<script>
	import ShootingStar from '$src/components/ShootingStar.svelte';
	import Section from '$src/components/Section.svelte';
	import Stars from '$src/components/Stars.svelte';
</script>

<div id="waitlist">
	<Section class="relative flex flex-col w-full">
		<div slot="background" class="absolute w-full h-full">
			<Stars />
		</div>

		<div class="relative flex flex-col w-full items-center pt-8 pb-40 md:pt-40 md:pb-60">
			<ShootingStar class="absolute w-[30%] -translate-x-[120%] translate-y-[15%]" />
			<ShootingStar class="absolute w-[20%] right-0 -translate-y-[40%]" delay={500} />
			<div class="relative flex flex-col items-center w-full">
				<div class="relative w-full max-w-xs md:max-w-2xl py-12">
					<div class="card bg-gradient-to-b p-[2px] from-primary-focus to-secondary text-center">
						<div class="w-full min-h-80 bg-base-100 rounded-2xl flex items-center justify-center">
							<div
								class="ml-form-embed"
								data-account="3570149:u6q2w6g8i7"
								data-form="5047595:q1a6l1"
							/>
						</div>
					</div>
					<ShootingStar class="absolute w-[20%] right-0 " delay={1000} />
				</div>
			</div>
		</div>
	</Section>
</div>
