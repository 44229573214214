<script lang="ts">
	/** @type {import('./$types').PageData} */
	import Header from '$src/sections/home/Header.svelte';
	import Cards from '$src/sections/home/Cards.svelte';
	import Restore from '$src/sections/home/Restore.svelte';
	import Waitlist from '$src/sections/home/Waitlist.svelte';
	import SvelteSeo from 'svelte-seo';
</script>

<SvelteSeo
	title="Kelp Digital - Your Art. Your Terms. "
	description="Rights management software empowered by cutting-edge p2p technology to help all content creators — from photographers & visual artists to musicians and developers — regain control of their work online and share and license it on their terms."
	keywords="kelp, kelp digital, content creators, photography, ownership economy, 
	copyright statements, ownership statements, web3, rights management, 
	restore creative balance "
	twitter={{
		site: '@kelp_digital',
		title: 'Kelp Digital - Your Art. Your Terms. ',
		description:
			'Rights management software empowered by cutting-edge p2p technology to help all content creators — from photographers & visual artists to musicians and developers — regain control of their work online and share and license it on their terms.',
		image:
			'https://ipfs.io/ipfs/QmRHnPw8t8zPhA19SYszScSV7p4NBLhziqtT2zcQJvNoip?filename=kelp_twitter_card_home.png',
		imageAlt: 'purple anagolay seaweed with digital artwork icons on blue background'
	}}
/>

<Header />
<Cards />
<Restore />
<Waitlist />
