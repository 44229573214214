<svg
	id="eNSxQFCbDVk1"
	class="absolute"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	viewBox="0 0 1603 950"
	shape-rendering="geometricPrecision"
	text-rendering="geometricPrecision"
>
	<defs>
		<filter id="eNSxQFCbDVk3-filter" x="-150%" width="400%" y="-150%" height="400%">
			<feGaussianBlur
				id="eNSxQFCbDVk3-filter-drop-shadow-0-blur"
				in="SourceAlpha"
				stdDeviation="20,20"
			/>
			<feOffset id="eNSxQFCbDVk3-filter-drop-shadow-0-offset" dx="10" dy="0" result="tmp" />
			<feFlood id="eNSxQFCbDVk3-filter-drop-shadow-0-flood" flood-color="#673ab7" />
			<feComposite id="eNSxQFCbDVk3-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
			<feMerge id="eNSxQFCbDVk3-filter-drop-shadow-0-merge">
				<feMergeNode id="eNSxQFCbDVk3-filter-drop-shadow-0-merge-node-1" />
				<feMergeNode id="eNSxQFCbDVk3-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
			</feMerge>
		</filter>
		<linearGradient
			id="eNSxQFCbDVk3-fill"
			x1="0.601335"
			y1="0.029965"
			x2="0.405366"
			y2="0.984923"
			spreadMethod="pad"
			gradientUnits="objectBoundingBox"
			gradientTransform="translate(0 0)"
		>
			<stop id="eNSxQFCbDVk3-fill-0" offset="0%" stop-color="#141f45" />
			<stop id="eNSxQFCbDVk3-fill-1" offset="100%" stop-color="#070d20" />
		</linearGradient>
	</defs>
	<path
		d="M1534.84,662.872c-9.26,31.359-38.49,55.302-83.65,71.681-45.14,16.375-106.09,25.151-178.58,26.241-144.96,2.18-335.936-26.378-538.553-86.205-202.616-59.827-378.473-139.584-499.001-220.157-60.267-40.289-106.675-80.765-135.6835-119.035-29.0156-38.28-40.5586-74.264-31.2991-105.623s38.4976-55.301,83.6526-71.681c45.143-16.375,106.094-25.15,178.579-26.241c144.963-2.18,335.938,26.378,538.554,86.205c202.621,59.827,378.471,139.585,499.001,220.158c60.27,40.288,106.68,80.764,135.68,119.034c29.02,38.28,40.56,74.264,31.3,105.623Z"
		transform="translate(0 40)"
		fill="none"
		stroke="#6c6884"
	/>
	<ellipse
		rx="416.21841"
		ry="416.21841"
		transform="matrix(1.057143 0 0 1.057143 775.317645 468)"
		filter="url(#eNSxQFCbDVk3-filter)"
		fill="url(#eNSxQFCbDVk3-fill)"
		stroke-width="0"
	/>
	<path
		d="M1272.61,760.794c-144.96,2.18-335.936-26.378-538.553-86.205-202.616-59.827-378.473-139.584-499.001-220.157"
		transform="translate(0 40)"
		fill="none"
		stroke="#595872"
	/>
</svg>
<svg
	class="absolute animated-svg"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	viewBox="0 0 1603 950"
	shape-rendering="geometricPrecision"
	text-rendering="geometricPrecision"
>
	<style>
		#eNSxQFCbDVk6_to {
			animation: eNSxQFCbDVk6_to__to 8000ms linear infinite normal forwards;
		}

		@keyframes eNSxQFCbDVk6_to__to {
			0% {
				offset-distance: 0%;
			}

			50% {
				offset-distance: 48.174699%;
			}

			100% {
				offset-distance: 100%;
			}
		}

		#eNSxQFCbDVk8_to {
			animation: eNSxQFCbDVk8_to__to 8000ms linear infinite normal forwards;
		}

		@keyframes eNSxQFCbDVk8_to__to {
			0% {
				offset-distance: 0%;
			}

			50% {
				offset-distance: 48.158548%;
			}

			100% {
				offset-distance: 100%;
			}
		}

		#eNSxQFCbDVk12_to {
			animation: eNSxQFCbDVk12_to__to 8000ms linear infinite normal forwards;
		}

		@keyframes eNSxQFCbDVk12_to__to {
			0% {
				offset-distance: 0%;
			}

			50% {
				offset-distance: 50.054253%;
			}

			100% {
				offset-distance: 100%;
			}
		}

		#eNSxQFCbDVk15_to {
			animation: eNSxQFCbDVk15_to__to 8000ms linear infinite normal forwards;
		}

		@keyframes eNSxQFCbDVk15_to__to {
			0% {
				offset-distance: 0%;
			}

			50% {
				offset-distance: 52.409694%;
			}

			100% {
				offset-distance: 100%;
			}
		}

		#eNSxQFCbDVk20_to {
			animation: eNSxQFCbDVk20_to__to 8000ms linear infinite normal forwards;
		}

		@keyframes eNSxQFCbDVk20_to__to {
			0% {
				offset-distance: 0%;
			}

			50% {
				offset-distance: 52.796962%;
			}

			100% {
				offset-distance: 100%;
			}
		}

		#eNSxQFCbDVk22_to {
			animation: eNSxQFCbDVk22_to__to 8000ms linear infinite normal forwards;
		}

		@keyframes eNSxQFCbDVk22_to__to {
			0% {
				offset-distance: 0%;
			}

			50% {
				offset-distance: 51.2776%;
			}

			100% {
				offset-distance: 100%;
			}
		}
	</style>
	<defs>
		<linearGradient
			id="eNSxQFCbDVk9-fill"
			x1="0"
			y1="-23.424"
			x2="0"
			y2="23.424"
			spreadMethod="pad"
			gradientUnits="userSpaceOnUse"
			gradientTransform="translate(0 0)"
		>
			<stop id="eNSxQFCbDVk9-fill-0" offset="0%" stop-color="#1b2132" />
			<stop id="eNSxQFCbDVk9-fill-1" offset="100%" stop-color="#0a1022" />
		</linearGradient>
		<linearGradient
			id="eNSxQFCbDVk9-stroke"
			x1="21.74"
			y1="-23.424"
			x2="27.16"
			y2="21.998"
			spreadMethod="pad"
			gradientUnits="userSpaceOnUse"
			gradientTransform="translate(0 0)"
		>
			<stop id="eNSxQFCbDVk9-stroke-0" offset="0%" stop-color="#673ab7" />
			<stop id="eNSxQFCbDVk9-stroke-1" offset="100%" stop-color="#791ea4" />
		</linearGradient>
		<linearGradient
			id="eNSxQFCbDVk16-fill"
			x1="61.4293"
			y1="0"
			x2="61.4293"
			y2="122.859"
			spreadMethod="pad"
			gradientUnits="userSpaceOnUse"
			gradientTransform="translate(0 0)"
		>
			<stop id="eNSxQFCbDVk16-fill-0" offset="0%" stop-color="#1b2132" />
			<stop id="eNSxQFCbDVk16-fill-1" offset="100%" stop-color="#0a1022" />
		</linearGradient>
		<linearGradient
			id="eNSxQFCbDVk16-stroke"
			x1="118.439"
			y1="0"
			x2="132.67"
			y2="119.117"
			spreadMethod="pad"
			gradientUnits="userSpaceOnUse"
			gradientTransform="translate(0 0)"
		>
			<stop id="eNSxQFCbDVk16-stroke-0" offset="0%" stop-color="#673ab7" />
			<stop id="eNSxQFCbDVk16-stroke-1" offset="100%" stop-color="#791ea4" />
		</linearGradient>
		<linearGradient
			id="eNSxQFCbDVk23-fill"
			x1="-0.384956"
			y1="-37.2747"
			x2="0"
			y2="38.274"
			spreadMethod="pad"
			gradientUnits="userSpaceOnUse"
			gradientTransform="translate(0 0)"
		>
			<stop id="eNSxQFCbDVk23-fill-0" offset="0%" stop-color="#1b2132" />
			<stop id="eNSxQFCbDVk23-fill-1" offset="100%" stop-color="#0a1022" />
		</linearGradient>
		<linearGradient
			id="eNSxQFCbDVk23-stroke"
			x1="35.52"
			y1="-38.275"
			x2="44.38"
			y2="35.943"
			spreadMethod="pad"
			gradientUnits="userSpaceOnUse"
			gradientTransform="translate(0 0)"
		>
			<stop id="eNSxQFCbDVk23-stroke-0" offset="0%" stop-color="#673ab7" />
			<stop id="eNSxQFCbDVk23-stroke-1" offset="100%" stop-color="#791ea4" />
		</linearGradient>
	</defs>
	<g class="animated-svg">
		<g
			id="eNSxQFCbDVk6_to"
			style="offset-path:path('M1223.099976,398.015503C82.304488,-52.830682,-206.607963,267.951839,324.979992,551.091518C1367.830001,1033.924861,1924.760792,738.644598,1223.1,398.0155');offset-rotate:0deg"
		>
			<g transform="translate(-1223.099976,-358.015503)">
				<path
					d="M1221.31,350.238c2.16,3.297,5.66,5.485,9.57,5.99-3.3,2.162-5.49,5.655-5.99,9.565-2.16-3.297-5.66-5.485-9.57-5.99c3.3-2.161,5.49-5.655,5.99-9.565Z"
					fill="#d1c4e9"
				/>
			</g>
		</g>
		<g
			id="eNSxQFCbDVk8_to"
			style="offset-path:path('M1367.829957,486.322998C638.410905,15.992629,-266.624807,122.593728,182.78715,451.020405C755.775528,890.292707,1994.332865,923.789932,1367.830001,486.322983');offset-rotate:0deg"
		>
			<g transform="translate(-1367.83,-446.323165)">
				<circle
					r="22.4244"
					transform="translate(1367.83 446.323)"
					fill="url(#eNSxQFCbDVk9-fill)"
					stroke="url(#eNSxQFCbDVk9-stroke)"
					stroke-width="2"
				/>
				<g>
					<path
						d="M1376.21,444.853c1.13.653,1.13,2.287,0,2.941l-11.3,6.526c-1.13.653-2.55-.164-2.55-1.471v-13.052c0-1.307,1.42-2.124,2.55-1.47l11.3,6.526Z"
						fill="#d1c4e9"
					/>
				</g>
			</g>
		</g>
		<g
			id="eNSxQFCbDVk12_to"
			style="offset-path:path('M1529.520813,627.142517C1282.429511,262.240601,-79.144829,-39.807087,81.318005,352.745195C252.697507,676.249311,1675.440832,1028.223951,1529.520813,614.998749');offset-rotate:0deg"
		>
			<g transform="translate(-1529.520813,-614.998688)">
				<path
					d="M1542.43,613.299c-.17-1.865-1.83-3.242-3.69-3.071-.94.086-1.76-.601-1.85-1.535l-.31-3.383c-.43-4.662-4.57-8.107-9.23-7.678-4.67.428-8.11,4.57-7.68,9.233l.31,3.382c.09.934-.6,1.761-1.54,1.847-1.86.171-3.24,1.828-3.07,3.693l1.25,13.529c.17,1.865,1.82,3.243,3.69,3.071l20.29-1.865c1.87-.172,3.25-1.828,3.07-3.693l-1.24-13.53Zm-19.38-6.745c-.26-2.797,1.81-5.283,4.61-5.54s5.28,1.81,5.54,4.607l.31,3.383c.08.933-.6,1.76-1.54,1.846l-6.76.622c-.94.086-1.76-.602-1.85-1.536l-.31-3.382Z"
					fill="#d1c4e9"
				/>
				<path
					d="M1542.43,613.299c-.17-1.865-1.83-3.242-3.69-3.071-.94.086-1.76-.601-1.85-1.535l-.31-3.383c-.43-4.662-4.57-8.107-9.23-7.678-4.67.428-8.11,4.57-7.68,9.233l.31,3.382c.09.934-.6,1.761-1.54,1.847-1.86.171-3.24,1.828-3.07,3.693l1.25,13.529c.17,1.865,1.82,3.243,3.69,3.071l20.29-1.865c1.87-.172,3.25-1.828,3.07-3.693l-1.24-13.53Zm-19.38-6.745c-.26-2.797,1.81-5.283,4.61-5.54s5.28,1.81,5.54,4.607l.31,3.383c.08.933-.6,1.76-1.54,1.846l-6.76.622c-.94.086-1.76-.602-1.85-1.536l-.31-3.382Z"
					fill="none"
					stroke="#fff"
					stroke-width="0.849034"
				/>
			</g>
		</g>
		<g
			id="eNSxQFCbDVk15_to"
			style="offset-path:path('M1436.100237,776.529911C1950.103454,594.167665,445.564485,-24.562186,95.522863,232.07852C-142.853744,433.24736,967.045533,908.002379,1436.232268,776.529908');offset-rotate:0deg"
		>
			<g transform="translate(-1436.100236,-736.529905)">
				<circle
					r="60.4293"
					transform="matrix(-1 0 0 1 1429.26001 724.14502)"
					fill="url(#eNSxQFCbDVk16-fill)"
					stroke="url(#eNSxQFCbDVk16-stroke)"
					stroke-width="2"
				/>
				<g>
					<path
						d="M1451.44,702.931l-39.31-4.252c-3.17-.343-6.02,1.904-6.36,5.014l-3.83,35.417c-.33,3.106,1.97,5.908,5.14,6.25l39.31,4.252c3.17.342,6.02-1.902,6.35-5.008l3.83-35.417c.34-3.11-1.96-5.914-5.13-6.256Zm-13.11,7.06c3.06.331,5.28,3.036,4.96,6.04-.33,3.004-3.08,5.171-6.14,4.84-3.07-.331-5.29-3.036-4.96-6.04.32-3.004,3.07-5.172,6.14-4.84Zm6.82,34.22l-17.85-1.932-17.06-1.845c-1.54-.165-2.1-1.326-1.27-2.593l11.53-17.462c.83-1.266,2.16-1.243,2.96.05l8.25,13.305c.81,1.294,2.42,1.571,3.62.619l2.59-2.073c1.19-.952,2.76-.648,3.51.677l5.15,9.151c.74,1.327.1,2.268-1.43,2.103Z"
						fill="#d1c4e9"
					/>
					<path
						d="M1451.44,702.931l-39.31-4.252c-3.17-.343-6.02,1.904-6.36,5.014l-3.83,35.417c-.33,3.106,1.97,5.908,5.14,6.25l39.31,4.252c3.17.342,6.02-1.902,6.35-5.008l3.83-35.417c.34-3.11-1.96-5.914-5.13-6.256Zm-13.11,7.06c3.06.331,5.28,3.036,4.96,6.04-.33,3.004-3.08,5.171-6.14,4.84-3.07-.331-5.29-3.036-4.96-6.04.32-3.004,3.07-5.172,6.14-4.84Zm6.82,34.22l-17.85-1.932-17.06-1.845c-1.54-.165-2.1-1.326-1.27-2.593l11.53-17.462c.83-1.266,2.16-1.243,2.96.05l8.25,13.305c.81,1.294,2.42,1.571,3.62.619l2.59-2.073c1.19-.952,2.76-.648,3.51.677l5.15,9.151c.74,1.327.1,2.268-1.43,2.103Z"
						fill="none"
						stroke="#fff"
						stroke-width="0.5"
					/>
				</g>
			</g>
		</g>
		<g
			id="eNSxQFCbDVk20_to"
			style="offset-path:path('M1273.549988,801.889496C2023.149001,771.231752,1006.395271,100.717263,235.056,171.742889C-280.154954,270.322684,467.892087,764.145449,1273.549988,801.889496');offset-rotate:0deg"
		>
			<g transform="translate(-1273.549988,-761.889496)">
				<path
					d="M1271.76,754.112c2.16,3.296,5.66,5.485,9.57,5.99-3.3,2.161-5.49,5.655-5.99,9.565-2.17-3.297-5.66-5.485-9.57-5.991c3.3-2.161,5.49-5.655,5.99-9.564Z"
					fill="#d1c4e9"
				/>
			</g>
		</g>
		<g
			id="eNSxQFCbDVk22_to"
			style="offset-path:path('M1140.459293,794.112C1925.480303,857.642503,1489.689311,319.61809,422.45244,171.742889C-201.318483,115.394158,-23.732455,609.739837,1140.074283,794.112004');offset-rotate:0deg"
		>
			<g transform="translate(-1143.180054,-756.783996)">
				<circle
					r="37.2747"
					transform="translate(1143.18 756.552826)"
					fill="url(#eNSxQFCbDVk23-fill)"
					stroke="url(#eNSxQFCbDVk23-stroke)"
					stroke-width="2"
				/>
				<g>
					<path
						d="M1155.42,740.926c-.04-.291-.14-.57-.3-.817-.16-.246-.37-.454-.62-.609s-.53-.252-.82-.285c-.29-.034-.59-.002-.87.093l-19.16,6.537c-.43.148-.8.442-1.04.831-.25.39-.35.852-.29,1.307l2.11,15.952c.14,1.015-.72,1.885-1.73,2.021-3.26.431-5.68,2.542-5.39,4.716.29,2.175,3.17,3.588,6.43,3.157s5.67-2.542,5.38-4.717l-1.94-14.719c-.11-.803.37-1.568,1.13-1.83l12.05-4.111c1.02-.346,2.09.323,2.23,1.385l1,7.562c.14,1.014-.72,1.884-1.73,2.02-3.26.431-5.67,2.542-5.39,4.717.29,2.175,3.17,3.587,6.43,3.156s5.67-2.541,5.38-4.716l-2.86-21.65Z"
						fill="#d1c4e9"
					/>
				</g>
			</g>
		</g>
	</g>
</svg>
<svg
	id="eNSxQFCbDVk1"
	class="absolute"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	viewBox="0 0 1603 950"
	shape-rendering="geometricPrecision"
	text-rendering="geometricPrecision"
>
	<defs>
		<linearGradient
			id="eNSxQFCbDVk26-fill"
			x1="0.560732"
			y1="0.412424"
			x2="0.471646"
			y2="0.775707"
			spreadMethod="pad"
			gradientUnits="objectBoundingBox"
			gradientTransform="translate(0 0)"
		>
			<stop id="eNSxQFCbDVk26-fill-0" offset="0%" stop-color="#141f45" />
			<stop id="eNSxQFCbDVk26-fill-1" offset="100%" stop-color="rgba(13,22,46)" />
		</linearGradient>
	</defs>
	<path
		d="M340.912888,392.905314C374.814613,198.093,532.311021,27.997621,775.317621,27.997621s435.404249,186.246744,435.404249,429.253344c0,131.990068-52.08973,218.759729-52.08973,218.759729-184.92793-14.07736-380.584923-81.199119-547.395933-144.400933-90.609568-24.149521-270.323319-107.215217-270.323319-138.704447Z"
		transform="matrix(1.004178 0 0 1-.460266 0)"
		fill="url(#eNSxQFCbDVk26-fill)"
		stroke-width="0"
	/>
</svg>
